<template>
  <br/>
</template>

<script>

export default {
  name: 'HomeComponent',
  mounted: function () {
  },
  components: {
  }
}
</script>