<template>
  
<div class="header">
  <span class="star outside" style="transform: rotate(-20deg);">&#9733;</span>
  <span class="star">&#9733;</span>
  <span class="star outside" style="transform: rotate(20deg);">&#9733;</span>
    
  </div>
  <div class="middle">
    <h2>CRISSY</h2> 
    <h1>UTTECH</h1>
  </div>
  <div class="bottom">
    <h2>FOR ISD 728 SCHOOL BOARD</h2>
  </div>

<div>
    <br/><br/><b><router-link to="/">Learn more about Crissy</router-link></b><br/>
</div>

<div class="questions">
 I believe that every voter should be educated about who they are voting for and how that candidate will represent their constituents.  As such, I’ve provided links to both the Elk River Star News questionnaire and the Elk River Area Chamber of Commerce Candidate Forum for all candidates
</div>

<div class="questions">
    <h1>Elk River Star News</h1>
    <div>The Elk River Star News asked each candidate a series of questions.  Below are links to the responses of each candidate.</div>

    <ul>
        <li><a class="crissy" href="https://www.hometownsource.com/elk_river_star_news/crissy-uttech-one-of-six-people-to-seek-one-of-two-at-large-seat-available/article_95895d2c-4c26-11ed-bf56-e76e6f22ec9c.html#tncms-source=infinity-scroll-summary-siderail-next" target="hometownsource">Crissy Uttech</a></li>
        <li><a href="https://www.hometownsource.com/elk_river_star_news/sarah-hamlin-one-of-six-people-to-seek-one-of-two-at-large-seats-available/article_70114aaa-4c21-11ed-a88e-5b36af93bb6d.html#tncms-source=infinity-scroll-summary-siderail-next" target="hometownsource">Sarah Hamlin</a></li>
        <li><a href="https://www.hometownsource.com/elk_river_star_news/incumbent-kim-michels-one-of-six-people-to-seek-one-of-two-at-large-seat/article_58e1fddc-4c23-11ed-8467-0f6db0283d0c.html#tncms-source=infinity-scroll-summary-siderail-next" target="hometownsource">Kim Michels</a></li>
        <li><a href="https://www.hometownsource.com/elk_river_star_news/jamie-plantenberg-selbitschka-one-of-six-people-to-seek-one-of-two-at-large-seat/article_155e658a-4c25-11ed-aba6-4b11ee084f2c.html#tncms-source=infinity-scroll-summary-siderail-next" target="hometownsource">Jamie Plantenberg-Selbitschka</a></li>


        <li><a href="https://www.hometownsource.com/elk_river_star_news/j-brian-calva-one-of-six-people-to-seek-one-of-two-at-large-seat/article_6c5fe6ba-4c20-11ed-bdae-4b3046c38ffd.html#tncms-source=infinity-scroll-summary-siderail-next" target="hometownsource">J.Brian Calva</a></li>
        <li><a href="https://www.hometownsource.com/elk_river_star_news/john-anderson-one-of-six-people-to-seek-one-of-two-at-large-seat-available/article_f087779e-4c1d-11ed-9a17-2733d03387a3.html#tncms-source=infinity-scroll-summary-siderail-next" target="hometownsource">John R. Anderson</a></li>
        
        <li><a href="https://www.hometownsource.com/elk_river_star_news/isd-728-school-board-election-district-1-incumbent-steinbrecher-faces-freiberg/article_2c512fc6-4c33-11ed-b698-57233278ba57.html" target="hometownsource">Shane Steinbrecher</a></li>
        <li><a href="https://www.hometownsource.com/elk_river_star_news/isd-728-school-board-election-district-1-freiberg-challenges-incumbent-steinbrecher/article_5fe22a90-4c31-11ed-a34c-5bd624c24158.html" target="hometownsource">Mindy Freiberg</a></li>        
    </ul>
</div>

<div class="questions">
<h1>Elk River Area Chamber of Commerce</h1>
<div><a href="https://www.elkriverchamber.org/" target="_new">The Elk River Area Chamber of Commerce</a> hosted a Candidate Forum on October 5th.  Below are the questions that were asked and the responses of each candidate (listed in the order of the answer as directed by the moderator).</div>
<br/><br/>
<b>1. Introduce yourself and let us know given the statutory scope and requirements placed on school boards, what impact do you want to make during your term in office? <a target="isd728schoolboard" href="https://youtu.be/zJ9EQ5__2qg?t=572">[View]</a></b>
<br/>
<ul>
<li><a target="isd728schoolboard" href="https://youtu.be/zJ9EQ5__2qg?t=605">Kim Michels</a></li>
<li><a target="isd728schoolboard" href="https://youtu.be/zJ9EQ5__2qg?t=707">J.Brian Calva </a></li>
<li class="crissy"><a target="isd728schoolboard" href="https://youtu.be/zJ9EQ5__2qg?t=800" class="crissy">Crissy Uttech</a></li>
<li><a target="isd728schoolboard" href="https://youtu.be/zJ9EQ5__2qg?t=885">John R. Anderson</a></li>
<li><a target="isd728schoolboard" href="https://youtu.be/zJ9EQ5__2qg?t=997">Sarah Hamlin</a></li>
<li><a target="isd728schoolboard" href="https://youtu.be/zJ9EQ5__2qg?t=1053">Mindy Freiberg</a></li>
<li><a target="isd728schoolboard" href="https://youtu.be/zJ9EQ5__2qg?t=1129">Shane Steinbrecher</a></li>
</ul>


<b>2. In May of 2022 the state legislature identified a proposal that would spend down some of the State's $9 billion estimated surplus, known as the 4-4-4 deal.  That deal called for $1 billion to be spent in education.  How would you propose to spend any one-time dollars that may be available if legislatures are able to come to an agreement during the next legislative session? </b>
<a target="isd728schoolboard" href="https://youtu.be/zJ9EQ5__2qg?t=1265">[View]</a>
<br/>
<ul>
<li><a target="isd728schoolboard" href="https://youtu.be/zJ9EQ5__2qg?t=1296">Sarah Hamlin</a></li>
<li><a target="isd728schoolboard" href="https://youtu.be/zJ9EQ5__2qg?t=1325">John R. Anderson</a></li>
<li class="crissy"><a target="isd728schoolboard" href="https://youtu.be/zJ9EQ5__2qg?t=1381" class="crissy">Crissy Uttech</a></li>
<li><a target="isd728schoolboard" href="https://youtu.be/zJ9EQ5__2qg?t=1446">J.Brian Calva</a></li>
<li><a target="isd728schoolboard" href="https://youtu.be/zJ9EQ5__2qg?t=1559">Kim Michels</a></li>
<li><a target="isd728schoolboard" href="https://youtu.be/zJ9EQ5__2qg?t=1614">Shane Steinbrecher</a></li>
<li><a target="isd728schoolboard" href="https://youtu.be/zJ9EQ5__2qg?t=1674">Mindy Freiberg</a></li>
</ul>

<b>3. The current strategic plan was developed with input from parents, community members, business leaders, students, staff, and teachers and is intended to set direction through 2023.  What needs to be addressed in the next strategic plan for 2024 and beyond in the district?</b>
<a target="isd728schoolboard" href="https://youtu.be/zJ9EQ5__2qg?t=1708">[View]</a>
<br/>

<ul>
<li class="crissy"><a target="isd728schoolboard" href="https://youtu.be/zJ9EQ5__2qg?t=1733" class="crissy">Crissy Uttech</a></li>
<li><a target="isd728schoolboard" href="https://youtu.be/zJ9EQ5__2qg?t=1778">J.Brian Calva</a></li>
<li><a target="isd728schoolboard" href="https://youtu.be/zJ9EQ5__2qg?t=1851">Kim Michels</a></li>
<li><a target="isd728schoolboard" href="https://youtu.be/zJ9EQ5__2qg?t=1941">Shane Steinbrecher</a></li>
<li><a target="isd728schoolboard" href="https://youtu.be/zJ9EQ5__2qg?t=2035">Mindy Freiberg</a></li>
<li><a target="isd728schoolboard" href="https://youtu.be/zJ9EQ5__2qg?t=2110">Sarah Hamlin</a></li>
<li><a target="isd728schoolboard" href="https://youtu.be/zJ9EQ5__2qg?t=2150">John R. Anderson</a></li>
</ul>


<b>4. Issues of diversity, equity, and inclusion are at the forefront of a national debate.  What are your thoughts on this issue as they relate to serving on the ISD 728 school board?<a target="isd728schoolboard" href="https://youtu.be/zJ9EQ5__2qg?t=2261">[View]</a></b>
<br/>
<ul>
<li><a target="isd728schoolboard" href="https://youtu.be/zJ9EQ5__2qg?t=2292">John R. Anderson</a></li>
<li><a target="isd728schoolboard" href="https://youtu.be/zJ9EQ5__2qg?t=2386">Sarah Hamlin</a></li>
<li><a target="isd728schoolboard" href="https://youtu.be/zJ9EQ5__2qg?t=2426">Mindy Freiberg</a></li>
<li><a target="isd728schoolboard" href="https://youtu.be/zJ9EQ5__2qg?t=2530">Shane Steinbrecher</a></li>
<li class="crissy"><a target="isd728schoolboard" href="https://youtu.be/zJ9EQ5__2qg?t=2566" class="crissy">Crissy Uttech</a></li>
<li><a target="isd728schoolboard" href="https://youtu.be/zJ9EQ5__2qg?t=2599">J. Brian Calva</a></li>
<li><a target="isd728schoolboard" href="https://youtu.be/zJ9EQ5__2qg?t=2709">Kim Michels</a></li>
</ul>



<b>5. What is your top priority regarding public school spending? <a target="isd728schoolboard" href="https://youtu.be/zJ9EQ5__2qg?t=2764">[View]</a></b>
<br/>
<ul>
<li><a target="isd728schoolboard" href="https://youtu.be/zJ9EQ5__2qg?t=2776">Mindy Freiberg</a></li>
<li><a target="isd728schoolboard" href="https://youtu.be/zJ9EQ5__2qg?t=2798">Shane Steinbrecher</a></li>
<li><a target="isd728schoolboard" href="https://youtu.be/zJ9EQ5__2qg?t=2855">Kim Michels</a></li>
<li><a target="isd728schoolboard" href="https://youtu.be/zJ9EQ5__2qg?t=2936">J.Brian Calva</a></li>
<li class="crissy"><a target="isd728schoolboard" href="https://youtu.be/zJ9EQ5__2qg?t=3032" class="crissy">Crissy Uttech</a></li>
<li><a target="isd728schoolboard" href="https://youtu.be/zJ9EQ5__2qg?t=3107">John R. Anderson</a></li>
<li><a target="isd728schoolboard" href="https://youtu.be/zJ9EQ5__2qg?t=3230">Sarah Hamlin</a></li>
</ul>



<b>6. What role does our local education system have in preparing students for their future as employees, professionals, business owners, and parents? <a target="isd728schoolboard" href="https://youtu.be/zJ9EQ5__2qg?t=3295">[View]</a></b>
<br/>
<ul>
<li><a target="isd728schoolboard" href="https://youtu.be/zJ9EQ5__2qg?t=3327">Shane Steinbrecher</a></li>
<li><a target="isd728schoolboard" href="https://youtu.be/zJ9EQ5__2qg?t=3388">Mindy Freiberg</a></li>
<li><a target="isd728schoolboard" href="https://youtu.be/zJ9EQ5__2qg?t=3428">Sarah Hamlin</a></li>
<li><a target="isd728schoolboard" href="https://youtu.be/zJ9EQ5__2qg?t=3455">John R. Anderson</a></li>
<li class="crissy"><a target="isd728schoolboard" href="https://youtu.be/zJ9EQ5__2qg?t=3523" class="crissy">Crissy Uttech</a></li>
<li><a target="isd728schoolboard" href="https://youtu.be/zJ9EQ5__2qg?t=3561">J.Brian Calva</a></li>
<li><a target="isd728schoolboard" href="https://youtu.be/zJ9EQ5__2qg?t=3628">Kim Michels</a></li>
</ul>

<b>7. Can you define what Social Emotional Learning is and how it connects to soft-skills employers state many new hires lack?  So, this is about SEL, Social Emotional Learning, and how it connects to the soft skills. <a target="isd728schoolboard" href="https://youtu.be/zJ9EQ5__2qg?t=3737">[View]</a></b>
<br/>
<ul>
<li><a target="isd728schoolboard" href="https://youtu.be/zJ9EQ5__2qg?t=3758">Sarah Hamlin</a></li>
<li><a target="isd728schoolboard" href="https://youtu.be/zJ9EQ5__2qg?t=3811">Mindy Freiberg</a></li>
<li><a target="isd728schoolboard" href="https://youtu.be/zJ9EQ5__2qg?t=3869">Shane Steinbrecher</a></li>
<li><a target="isd728schoolboard" href="https://youtu.be/zJ9EQ5__2qg?t=3914">John R. Anderson</a></li>
<li class="crissy"><a target="isd728schoolboard" href="https://youtu.be/zJ9EQ5__2qg?t=3962" class="crissy">Crissy Uttech</a></li>
<li><a target="isd728schoolboard" href="https://youtu.be/zJ9EQ5__2qg?t=4010">J.Brian Calva</a></li>
<li><a target="isd728schoolboard" href="https://youtu.be/zJ9EQ5__2qg?t=4047">Kim Michels</a></li>
</ul>

<b>8. Should a school district superintendent be held accountable for reading, writing, math scores, etc? <a target="isd728schoolboard" href="https://youtu.be/zJ9EQ5__2qg?t=4101">[View]</a></b>
<br/>
<ul>
<li><a target="isd728schoolboard" href="https://youtu.be/zJ9EQ5__2qg?t=4109">John R. Anderson</a></li>
<li class="crissy"><a target="isd728schoolboard" href="https://youtu.be/zJ9EQ5__2qg?t=4115" class="crissy">Crissy Uttech</a></li>
<li><a target="isd728schoolboard" href="https://youtu.be/zJ9EQ5__2qg?t=4134">J.Brian Calva</a></li>
<li><a target="isd728schoolboard" href="https://youtu.be/zJ9EQ5__2qg?t=4138">Kim Michels</a></li>
<li><a target="isd728schoolboard" href="https://youtu.be/zJ9EQ5__2qg?t=4174">Shane Steinbrecher</a></li>
<li><a target="isd728schoolboard" href="https://youtu.be/zJ9EQ5__2qg?t=4202">Mindy Freiberg</a></li>
<li><a target="isd728schoolboard" href="https://youtu.be/zJ9EQ5__2qg?t=4218">Sarah Hamlin</a></li>
</ul>


<b>9. How much say should parents have in what is being taught to their children? <a target="isd728schoolboard" href="https://youtu.be/zJ9EQ5__2qg?t=4233">[View]</a></b>
<br/>
<ul>
<li><a target="isd728schoolboard" href="https://youtu.be/zJ9EQ5__2qg?t=4243">Kim Michels</a></li>
<li><a target="isd728schoolboard" href="https://youtu.be/zJ9EQ5__2qg?t=4306">J.Brian Calva</a></li>
<li class="crissy"><a target="isd728schoolboard" href="https://youtu.be/zJ9EQ5__2qg?t=4358" class="crissy">Crissy Uttech</a></li>
<li><a target="isd728schoolboard" href="https://youtu.be/zJ9EQ5__2qg?t=4403">John R. Anderson</a></li>
<li><a target="isd728schoolboard" href="https://youtu.be/zJ9EQ5__2qg?t=4463">Sarah Hamlin</a></li>
<li><a target="isd728schoolboard" href="https://youtu.be/zJ9EQ5__2qg?t=4483">Mindy Freiberg</a></li>
<li><a target="isd728schoolboard" href="https://youtu.be/zJ9EQ5__2qg?t=4544">Shane Steinbrecher</a></li>
</ul>


<b>Final Comment</b>
<br/>
<ul>
<li><a target="isd728schoolboard" href="https://youtu.be/zJ9EQ5__2qg?t=4650">Shane Steinbrecher</a></li>
<li><a target="isd728schoolboard" href="https://youtu.be/zJ9EQ5__2qg?t=4696">Mindy Freiberg</a></li>
<li><a target="isd728schoolboard" href="https://youtu.be/zJ9EQ5__2qg?t=4748">Sarah Hamlin</a></li>
<li><a target="isd728schoolboard" href="https://youtu.be/zJ9EQ5__2qg?t=4768">John R. Anderson</a></li>
<li class="crissy"><a target="isd728schoolboard" href="https://youtu.be/zJ9EQ5__2qg?t=4834" class="crissy">Crissy Uttech</a></li>
<li><a target="isd728schoolboard" href="https://youtu.be/zJ9EQ5__2qg?t=4868">J.Brian Calva</a></li>
<li><a target="isd728schoolboard" href="https://youtu.be/zJ9EQ5__2qg?t=4933">Kim Michels</a></li>
</ul>

</div>

  <Footer></Footer>
</template>

<script>
import Footer from '../components/Footer.vue'
import { event } from  'vue-gtag'
export default {
  name: 'CandidateForum',
  mounted: function () {
    event('page_view', { page_title: 'Candidate Responses' });
  },
  components: {
    Footer
  }
}
</script>

<style>
a, a:visited {
    color: #0092cb;
}

li {
    padding-top: 10px;
}
li.crissy {
    color: #0092cb;
}
a.crissy {
    color: #0092cb;
    font-weight: bold;
    font-size: 1.2em;
}
.questions {
    margin: 25px auto;
    max-width: 700px; 
    padding: 0 .5em;
    text-align: left;
}
</style>