<template>
    <router-view />
</template>

<style>
#app {
  font-family: serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

html, body {
  margin: 0;
  padding: 0;
}
div.header {
  background: #5C0F8B;
  padding-bottom: .4vw;  
}
div.middle {
  color: #00baf2;
}
div.middle h1 {
    font-size: 5em;
    margin: 0;
}
div.middle h2 {
    font-size: 3em;
    margin: 0;
    padding-top: 1.2vw;
}

div.bottom {
  background: #fdb713;
  padding: .4vw;
}
div.bottom h2 {
  color: black;
  font-size: 2em;
  margin: 0;
}
.header h1 {
  color: white;
  font-size: 3.5em;
  font-weight: bold;
  margin: 0;
}
.header h3 {
  color: #FDB713;
  font-size: 1.9em;
  font-weight: bold;
  margin: 0;
}

.star {
    color: white;
    display: inline-block;
    font-size: 5em;
    vertical-align: top;
}
.outside {
  padding: 50px 30px 0 30px;
}

</style>
