<template>
  <br/><br/>
  <div class="footer">
  Prepared and paid for by Crissy Uttech For ISD 728 School Board, 21956 Orchid Ave. Rogers, MN 55374
  </div>
</template>

<script>
export default {
  name: 'FooterComponent'
}
</script>

<style scoped>

.footer {
    color: black;
    font-size: 10pt;
    padding-bottom: 20px;
}
</style>
