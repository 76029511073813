import { createApp } from 'vue'
import {createRouter, createWebHashHistory} from "vue-router";

import App from './App.vue'
import VueGtag from "vue-gtag";

import HomeComponent from '@/views/Home.vue'
import CandidateForum from '@/views/CandidateForum.vue'

const routes = [
    { path: '/', component: HomeComponent },
    { path: '/CandidateResponses', component: CandidateForum },
  ]
  const router = createRouter({
    history: createWebHashHistory(),
    routes, // short for `routes: routes`
  })
  
  
  const app = createApp(App)
  .use(VueGtag, {
    config: { id: "G-49ZY70NKKR" }
  })  
  app.use(router)
  
  app.mount('#app')